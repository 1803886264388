<template>
  <div>
    <el-row style="margin:10px;">
      <el-table
        size="small"
        :data="tableData1"
        tooltip-effect="dark"
        class="tableClass"
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }"
        stripe
        style="width: 100%"
      >
        <el-table-column
          :label="$t('System.BehaviorLevelConfig.SerialNumber')"
          width="80"
        >
          <template slot-scope="scope">
            <div>
              {{ (page.currentPage - 1) * page.pageSize + scope.$index + 1 }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$t('System.BehaviorLevelConfig.BehaviorType')"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="level"
          :label="$t('System.BehaviorLevelConfig.BehaviorLevel')"
          width="280"
        >
          <template v-slot="{ row }">
            <el-radio-group v-model="row.level" @change="changeRadio1(row)">
              <el-radio label="3"
                ><span style="color: #c72d2c">{{
                  $t("System.BehaviorLevelConfig.tall")
                }}</span></el-radio
              >
              <el-radio label="2"
                ><span style="color: #f59a23">{{
                  $t("System.BehaviorLevelConfig.centre")
                }}</span></el-radio
              >
              <el-radio label="1"
                ><span style="color: #00cdcd">{{
                  $t("System.BehaviorLevelConfig.low")
                }}</span></el-radio
              >
            </el-radio-group>
          </template>
        </el-table-column>
        <el-table-column
          width="700"
          prop="description"
          :show-overflow-tooltip="true"
          :label="$t('System.BehaviorLevelConfig.describe')"
        >
        </el-table-column>
        <el-table-column
          prop="updateTime"
          :label="$t('System.BehaviorLevelConfig.ModificationTime')"
        >
        </el-table-column>
      </el-table>
      <div class="pagination-panel" style="font-size: 12px">
        <pagination
          style="margin-right: 10px"
          :page="page.currentPage"
          :limit="page.pageSize"
          :total="page.total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        ></pagination>
      </div>
    </el-row>
    
  </div>
</template>

<script>
import pagination from "@/components/pagination/page.vue";
export default {
  components: { pagination },
  data() {
    return {
      index: 1,
      tableData1: [], //el-table  绑定的数据
      category: "1",
      page: {
        currentPage: 1, //当前页码
        pageSize: 10, //每页显示条数
        total: 0, //返回数据总条数
      },
    };
  },
  mounted() {
    this.getViolaLevelList();
  },
  methods: {
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.currentPage = page;
      this.page.pageSize = limit;
      //调用列表方法
      this.getViolaLevelList();
    },
    async getViolaLevelList() {
      var params = {
        currentPage: this.page.currentPage, //"当前页"
        pageSize: this.page.pageSize, //"每页多少数据"
      };
      const res = await this.$axios.post(
        "/httpServe/level/violation/list",
        params,
        true
      );
      // console.log(res,"违规行为列表")
      this.tableData1 = res.data.content;
      this.tableData1.forEach((i, index) => {
        i.level = i.level + "";
        this.$set(i, "value", index + 1);
      });
      this.page.total = res.data.total;
    },
    changeRadio1(v) {
      this.$confirm(
        this.$t("System.BehaviorLevelConfig.sureUpBehaviorLevel"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(() => {
          //编辑
          var params = {
            id: v.id, //id
            level: v.level, //行为级别
            enName: v.enName,
          };
          const res = this.$axios.post(
            "/httpServe/level/violation/update",
            params,
            true
          );
          setTimeout(() => {
            this.getViolaLevelList();
          }, 1000);
        })
        .catch(() => {
          this.getViolaLevelList();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-table {
  width: 100px;
  table-layout: fixed; /* 只有定义了表格的布局算法为fixed，下面td的定义才能起作用。 */
}
.el-table__header-wrapper {
  .has-gutter {
    tr {
      th:first-of-type {
        border-right: 1px solid #e4e7ed;
      }
      th:last-of-type {
        border-left: 1px solid #e4e7ed;
      }
      th:nth-of-type(3) {
        border-right: 1px solid #e4e7ed;
        border-left: 1px solid #e4e7ed;
      }
    }
  }
}
::v-deep .el-pager li {
  margin: 0 3px !important;
  min-width: 22px !important;
  line-height: 22px !important;
  height: 22px !important;
  font-size: 12px !important;
}
::v-deep .btn-prev {
  margin: 0 3px !important;
  min-width: 22px !important;
  line-height: 22px !important;
  height: 22px !important;
  font-size: 12px !important;
}

::v-deep .btn-next {
  margin: 0 3px !important;
  min-width: 22px !important;
  line-height: 22px !important;
  height: 22px !important;
  font-size: 12px !important;
}
::v-deep .el-pagination__editor.el-input .el-input__inner {
  height: 22px;
}
</style>