<template>
  <div class="BehaviorLevelWrap">
    <!-- <navi-gation /> -->
    <el-tabs v-model="activeName" type="card" @tab-click="handleLoad" style="margin: 10px;" class="tabs">
      <el-tab-pane :label="$t('System.BehaviorLevelConfig.ViolationLevel')" name="1">
        <keep-alive>
          <violationLevel :key="timer1" v-if="activeName == '1'" />
        </keep-alive>
      </el-tab-pane>
      <el-tab-pane :label="$t('System.BehaviorLevelConfig.ThreatLevel')" name="2">
        <keep-alive>
          <threatLevel :key="timer2" v-if="activeName == '2'" />
        </keep-alive>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import threatLevel from '../../components/BehaviorLevelConfig/threatLevel.vue'
import violationLevel from '../../components/BehaviorLevelConfig/violationLevel.vue'
import naviGation from '@/components/hearder/index'
export default {
  components: {
    threatLevel,
    violationLevel,
    naviGation
  },
  data() {
    return {
      activeName: '1',
      timer1: '',
      timer2: ''
    }
  },
  mounted() {},
  methods: {
    handleLoad(data) {
      let name = data.name
      if (name == 1) {
        this.timer1 = new Date().getTime()
      } else if (name == 2) {
        this.timer2 = new Date().getTime()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 0px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
.BehaviorLevelWrap {
  .el-tabs--card > .el-tabs__header .el-tabs__item {
    border-bottom: 1px solid transparent;
    padding: 0px 20px;
  }
  .el-tabs--border-card > .el-tabs__content {
    padding: 15px 0px !important;
  }

  .el-tabs--card > .el-tabs__header {
    border-bottom: 1px solid #e4e7ed !important;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: 1px solid #e4e7ed;
    border-bottom: none;
    border-radius: 0px;
    box-sizing: border-box;
  }
  .tabs {
    border: 1px solid #e4e7ed !important;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: 0px solid #e4e7ed !important;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__item {
    border-left: 0px solid #e4e7ed !important;
    border-right: 0px solid #e4e7ed !important;
  }
}
</style>
